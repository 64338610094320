import { Link } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./header.module.css";

const Header = () => (
  <div className={styles.root}>
    <div className={styles.wrapper}>
      <div className={styles.branding}>
        <Link to="/">
          <StaticImage
            src="../../images/seedling_1.png"
            alt="Seedling Icon"
            width={35}
            height={50}
          />
        </Link>
      </div>

      <nav className={styles.nav}>
        <ul>
          <li>
            <Link to="/" activeClassName={styles.active}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/about/" activeClassName={styles.active}>
              About
            </Link>
          </li>
          <li>
            <Link to="/blog/" activeClassName={styles.active}>
              Blog
            </Link>
          </li>
          <li>
            <Link to="/work/" activeClassName={styles.active}>
              Work
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
);

export default Header;
